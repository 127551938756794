import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import IconButton from '../../../../shared/components/button/icon/icon';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './header-mobile.scss';
var HeaderMobile = /** @class */ (function (_super) {
    __extends(HeaderMobile, _super);
    function HeaderMobile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(HeaderMobile.prototype, "title", {
        get: function () {
            var title = this.props.title;
            if (!title) {
                return null;
            }
            return (React.createElement("h1", { "data-hook": "modal-title", className: styles.title }, title));
        },
        enumerable: true,
        configurable: true
    });
    HeaderMobile.prototype.render = function () {
        var _a = this.props, onButtonClick = _a.onButtonClick, closeButtonLabel = _a.closeButtonLabel;
        return (React.createElement("header", { "data-hook": "modal-header", className: styles.header },
            this.title,
            React.createElement(IconButton, { dataHook: "header-close-button", className: styles['close-button'], onClick: onButtonClick, ariaLabel: closeButtonLabel, name: "close" })));
    };
    HeaderMobile.propTypes = {
        title: PropTypes.any,
        closeButtonLabel: PropTypes.string,
        onButtonClick: PropTypes.func,
    };
    HeaderMobile.defaultProps = {
        onButtonClick: _.noop,
    };
    HeaderMobile = __decorate([
        withStyles(styles)
    ], HeaderMobile);
    return HeaderMobile;
}(React.Component));
export default HeaderMobile;
