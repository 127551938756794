import { __decorate, __extends } from "tslib";
import React from 'react';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './slider-empty-state.scss';
import fedopsLogger from '../../../loggers/fedops/new-fedops-logger';
var SliderEmptyState = /** @class */ (function (_super) {
    __extends(SliderEmptyState, _super);
    function SliderEmptyState() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderEmptyState.prototype.render = function () {
        fedopsLogger.renderNoContent.end();
        return (React.createElement("div", { className: styles.sliderEmptyState }, i18n.t('widget.this-channel-is-coming-soon')));
    };
    SliderEmptyState = __decorate([
        withStyles(styles)
    ], SliderEmptyState);
    return SliderEmptyState;
}(React.Component));
export { SliderEmptyState };
