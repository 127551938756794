import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import pubsub from '../../utils/pubsub';
import { byPopoutSide } from '../../utils/popout/calculate-position';
import events from '../../constants/events';
import { sides, popoutPositions, trianglePositions, } from '../../constants/popout';
import Popout from '../popout/popout';
import { withStyles } from '../../utils/withStyles';
import styles from './tooltip.scss';
var HIDE_TIMEOUT = 200;
var hideTimeoutId;
export function showTooltip(config) {
    pubsub.publish(events.TOOLTIP.SHOW, config);
}
export function hideTooltip() {
    pubsub.publish(events.TOOLTIP.HIDE);
}
var TooltipPopout = /** @class */ (function (_super) {
    __extends(TooltipPopout, _super);
    function TooltipPopout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            popoutComponent: Popout,
            visible: false,
        };
        _this.showTooltip = function (config) {
            if (config.compId !== _this.props.compId) {
                return;
            }
            _this.preventHide();
            _this.setState(__assign({ visible: true, preventHideOnHover: false, popoutComponent: Popout, className: '', innerClassName: '', triangleClassName: '', content: null, popoutSide: sides.RIGHT, popoutPosition: popoutPositions.CENTER, trianglePosition: trianglePositions.CENTER, referenceElement: null }, config));
        };
        _this.hideTooltip = function () {
            var timeout = _this.state.preventHideOnHover ? HIDE_TIMEOUT : 0;
            hideTimeoutId = setTimeout(function () {
                _this.setState({
                    visible: false,
                });
            }, timeout);
        };
        _this.handleMouseEnter = function () {
            if (_this.state.preventHideOnHover) {
                _this.preventHide();
            }
        };
        _this.handleMouseLeave = function () {
            _this.hideTooltip();
        };
        _this.getPopoutRef = function (node) {
            _this.popout = node;
        };
        return _this;
    }
    TooltipPopout.prototype.componentDidMount = function () {
        this.showTooltipUnsubscribe = pubsub.subscribe(events.TOOLTIP.SHOW, this.showTooltip);
        this.hideTooltipUnsubscribe = pubsub.subscribe(events.TOOLTIP.HIDE, this.hideTooltip);
    };
    TooltipPopout.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.state, visible = _a.visible, tooltipId = _a.tooltipId;
        if (visible && !_.isEqual(this.state, prevState)) {
            // update position if popout is visible
            this.updatePosition();
        }
        if (visible !== prevState.visible) {
            var eventId = visible
                ? events.TOOLTIP.DID_SHOW
                : events.TOOLTIP.DID_HIDE;
            pubsub.publish(eventId, tooltipId);
        }
    };
    TooltipPopout.prototype.componentWillUnmount = function () {
        this.showTooltipUnsubscribe();
        this.hideTooltipUnsubscribe();
    };
    Object.defineProperty(TooltipPopout.prototype, "Popout", {
        get: function () {
            return this.state.popoutComponent;
        },
        enumerable: true,
        configurable: true
    });
    TooltipPopout.prototype.preventHide = function () {
        clearTimeout(hideTimeoutId);
    };
    TooltipPopout.prototype.calculatePosition = function () {
        var _a = this.state, referenceElement = _a.referenceElement, popoutSide = _a.popoutSide, popoutPosition = _a.popoutPosition, trianglePosition = _a.trianglePosition;
        return byPopoutSide(referenceElement, this.popout, {
            popoutSide: popoutSide,
            popoutPosition: popoutPosition,
            trianglePosition: trianglePosition,
        });
    };
    TooltipPopout.prototype.updatePosition = function () {
        var _a = this.calculatePosition(), top = _a.top, left = _a.left, offsetX = _a.offsetX;
        this.popout.style.top = top + "px";
        this.popout.style.left = left + "px";
        var transformStyle = "translateX(" + offsetX + "px) rotate(45deg)";
        var before = this.popout.firstElementChild;
        var after = this.popout.lastElementChild;
        before.style.transform = transformStyle;
        after.style.transform = transformStyle;
    };
    TooltipPopout.prototype.render = function () {
        var _a;
        var _b = this.state, visible = _b.visible, content = _b.content, left = _b.left, top = _b.top, className = _b.className, innerClassName = _b.innerClassName, triangleClassName = _b.triangleClassName, popoutSide = _b.popoutSide, popoutPosition = _b.popoutPosition, trianglePosition = _b.trianglePosition, onClickOutside = _b.onClickOutside;
        var classNames = classnames(className, styles.popout, (_a = {},
            _a[styles.positioned] = visible,
            _a));
        var innerClassNames = classnames(styles['popout-inner'], innerClassName);
        return (React.createElement(this.Popout, { getRef: this.getPopoutRef, onClickOutside: onClickOutside, popoutSide: popoutSide, popoutPosition: popoutPosition, trianglePosition: trianglePosition, className: classNames, innerClassName: innerClassNames, triangleClassName: triangleClassName, style: { left: left, top: top }, isActive: visible, onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave }, content));
    };
    TooltipPopout = __decorate([
        withStyles(styles)
    ], TooltipPopout);
    return TooltipPopout;
}(React.Component));
export default TooltipPopout;
