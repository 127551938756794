import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconButton from '../../../../shared/components/button/icon/icon';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './header.scss';
var ModalHeader = /** @class */ (function (_super) {
    __extends(ModalHeader, _super);
    function ModalHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ModalHeader.prototype, "title", {
        get: function () {
            var _a = this.props, title = _a.title, titleClassName = _a.titleClassName;
            if (!title) {
                return null;
            }
            return (React.createElement("h1", { "data-hook": "modal-title", className: classnames(styles.title, titleClassName) }, title));
        },
        enumerable: true,
        configurable: true
    });
    ModalHeader.prototype.render = function () {
        var _a = this.props, onButtonClick = _a.onButtonClick, className = _a.className, buttonClassName = _a.buttonClassName;
        var classNames = classnames(className, styles.header);
        return (React.createElement("header", { className: classNames },
            this.title,
            React.createElement(IconButton, { dataHook: "header-close-button", className: classnames(styles.button, styles['close-button'], buttonClassName), onClick: onButtonClick, name: "close" })));
    };
    ModalHeader.propTypes = {
        title: PropTypes.any,
        className: PropTypes.string,
        buttonClassName: PropTypes.string,
        titleClassName: PropTypes.string,
        onButtonClick: PropTypes.func,
    };
    ModalHeader.defaultProps = {
        onButtonClick: _.noop,
    };
    ModalHeader = __decorate([
        withStyles(styles)
    ], ModalHeader);
    return ModalHeader;
}(React.Component));
export default ModalHeader;
