import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '../../../../../shared/components/button/button';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './account-info.scss';
var AccountInfo = /** @class */ (function (_super) {
    __extends(AccountInfo, _super);
    function AccountInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccountInfo.prototype.render = function () {
        var _a = this.props, children = _a.children, userEmail = _a.userEmail, logoutLabel = _a.logoutLabel, onLogoutClick = _a.onLogoutClick;
        return (React.createElement("section", { "aria-labelledby": "account_btn_mobile_heading", className: styles.container },
            React.createElement("div", { className: "qa-account-button-user-email " + styles['user-email'], id: "account_btn_mobile_heading", role: "heading", "aria-label": i18n.t('widget.account.signed-as') + " " + userEmail }, userEmail),
            children ? React.createElement("div", null, children) : null,
            React.createElement("div", { className: styles['logout-wrapper'], role: "presentation" },
                React.createElement(LinkButton, { className: "qa-account-button-user-logout " + styles.logout, dataHook: "account-info-logout-button", ariaLabel: logoutLabel, onClick: onLogoutClick }, logoutLabel))));
    };
    AccountInfo.propTypes = {
        userEmail: PropTypes.string,
        logoutLabel: PropTypes.string,
        children: PropTypes.any,
        onLogoutClick: PropTypes.func,
    };
    AccountInfo = __decorate([
        withStyles(styles)
    ], AccountInfo);
    return AccountInfo;
}(React.Component));
export default AccountInfo;
