import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { logWidgetSystem } from '../../../../../shared/worker/actions/bi';
import { getCurrentSiteUser } from '../../../../../shared/selectors/current-site-user';
import { getVideosGroupedByIds } from '../../../../../shared/selectors/videos';
import { isVideoPlayingOptimistic } from '../../../../selectors/video-playback-status';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getVideosInRowCount, isThumbnailsPreviewHover, isVideosListInfoAlwaysShown, isVideosListInfoShownOnHover, isVideosListInfoPositionBelow, isRTL, } from '../../../../../shared/selectors/app-settings';
import { getSearchQuery } from '../../../../../shared/selectors/search';
import { getIsFetching } from '../../../../redux/client/lazy-channel-videos/selectors';
import ContentSlider from '@wix/wix-vod-shared/dist/src/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/src/widget/ui-components/page-list/page-list';
import { NextButton, PrevButton } from './_nav-button/nav-button';
import VideoThumbnailOverlay from '../../../../../shared/components/video-thumbnail-overlay/video-thumbnail-overlay';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './videos.scss';
var Videos = /** @class */ (function (_super) {
    __extends(Videos, _super);
    function Videos() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderVideoItem = function (videoId, videoAtPageIndex, isVisiblePage) {
            var _a;
            var _b = _this.props, channelData = _b.channelData, videoByIds = _b.videoByIds, thumbnailSize = _b.thumbnailSize, onThumbnailClick = _b.onThumbnailClick, onPlayRequest = _b.onPlayRequest, onPlayMemberOnlyRequest = _b.onPlayMemberOnlyRequest, selectedVideoId = _b.selectedVideoId, isSelectedVideoIndicationDisabled = _b.isSelectedVideoIndicationDisabled, videosInRowCount = _b.videosInRowCount, currentSiteUser = _b.currentSiteUser, isThumbnailsPreviewHover = _b.isThumbnailsPreviewHover, isInfoAlwaysShown = _b.isInfoAlwaysShown, isDescriptionBelow = _b.isDescriptionBelow, isInfoShownOnHover = _b.isInfoShownOnHover;
            var videoItem = videoByIds[videoId];
            var isVideoSelected = selectedVideoId === videoId && !isSelectedVideoIndicationDisabled;
            var isLastInRow = (videoAtPageIndex + 1) % videosInRowCount === 0;
            var classNames = classnames(styles['video-list-item'], (_a = {},
                _a[styles['last-in-row']] = isLastInRow,
                _a));
            return (React.createElement(VideoThumbnailOverlay, __assign({}, thumbnailSize, { key: videoId, className: classNames, currentSiteUser: currentSiteUser, channelData: channelData, videoItem: videoItem, isSelected: isVideoSelected, isContentFocusable: isVisiblePage, isThumbnailsPreviewHover: isThumbnailsPreviewHover, isInfoAlwaysShown: isInfoAlwaysShown, isDescriptionBelow: isDescriptionBelow, isInfoShownOnHover: isInfoShownOnHover, onPlayRequest: memoizedPartial(onPlayRequest, videoItem), onPlayMemberOnlyRequest: memoizedPartial(onPlayMemberOnlyRequest, videoId), onClick: memoizedPartial(onThumbnailClick, videoItem) })));
        };
        return _this;
    }
    Videos.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var currentVideosPageNumber = _a.currentVideosPageNumber;
        if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
            this.props.logWidgetSystem('videoList.changePage.rendered', {
                previousEventName: 'videoList.changePage.requested',
            });
        }
    };
    Videos.prototype.componentDidUpdate = function () {
        this.props.logWidgetSystem('videoList.searchByQuery.rendered', {
            previousEventName: 'videoList.searchByQuery.requested',
        });
        this.props.logWidgetSystem('videoList.searchByTag.rendered', {
            previousEventName: 'videoList.searchByTag.requested',
        });
        this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
            previousEventName: 'videoList.searchByCategory.requested',
        });
    };
    Videos.prototype.hasPage = function (pageNum) {
        var videoIdsByPageNumber = this.props.videoIdsByPageNumber;
        return Boolean(videoIdsByPageNumber[pageNum]);
    };
    Object.defineProperty(Videos.prototype, "isPrevPageVisible", {
        get: function () {
            var currentVideosPageNumber = this.props.currentVideosPageNumber;
            return this.hasPage(currentVideosPageNumber - 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Videos.prototype, "isNextPageVisible", {
        get: function () {
            var currentVideosPageNumber = this.props.currentVideosPageNumber;
            return this.hasPage(currentVideosPageNumber + 1);
        },
        enumerable: true,
        configurable: true
    });
    Videos.prototype.render = function () {
        var _a = this.props, currentVideosPageNumber = _a.currentVideosPageNumber, videoIdsByPageNumber = _a.videoIdsByPageNumber, containerWidth = _a.containerWidth, className = _a.className, onSlideToNext = _a.onSlideToNext, onSlideToPrev = _a.onSlideToPrev, isFetching = _a.isFetching, isRTL = _a.isRTL;
        return (React.createElement("div", { className: className },
            React.createElement(ContentSlider, { className: styles['slider-container'], width: containerWidth, currentPageNumber: currentVideosPageNumber, isRTL: isRTL },
                React.createElement(PageList, { pageWidth: containerWidth, currentPageNumber: currentVideosPageNumber, itemsByPageNumber: videoIdsByPageNumber, renderItem: this.renderVideoItem, isRTL: isRTL })),
            (this.isPrevPageVisible || this.isNextPageVisible || isFetching) && (React.createElement("div", { className: styles.buttons },
                React.createElement(PrevButton, { disabled: !this.isPrevPageVisible, onClick: onSlideToPrev }),
                React.createElement(NextButton, { disabled: !this.isNextPageVisible, onClick: onSlideToNext })))));
    };
    Videos.propTypes = {
        channelData: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        selectedVideoId: PropTypes.string.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        videoByIds: PropTypes.object.isRequired,
        videoIdsByPageNumber: PropTypes.array.isRequired,
        videosInRowCount: PropTypes.number.isRequired,
        videosPerPageCount: PropTypes.number.isRequired,
        currentVideosPageNumber: PropTypes.number.isRequired,
        containerWidth: PropTypes.number.isRequired,
        isSelectedVideoIndicationDisabled: PropTypes.bool.isRequired,
        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isInfoAlwaysShown: PropTypes.bool.isRequired,
        isInfoShownOnHover: PropTypes.bool.isRequired,
        isDescriptionBelow: PropTypes.bool.isRequired,
        thumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }).isRequired,
        onSlideToPrev: PropTypes.func.isRequired,
        onSlideToNext: PropTypes.func.isRequired,
        onThumbnailClick: PropTypes.func.isRequired,
        onPlayRequest: PropTypes.func.isRequired,
        onPlayMemberOnlyRequest: PropTypes.func.isRequired,
        currentSiteUser: PropTypes.object,
        className: PropTypes.string,
        isRTL: PropTypes.bool,
    };
    Videos = __decorate([
        connect(function (state) { return ({
            isFetching: getIsFetching(state),
            videoByIds: getVideosGroupedByIds(state),
            isVideoPlaying: isVideoPlayingOptimistic(state),
            selectedVideoId: getMainVideoId(state),
            videosInRowCount: getVideosInRowCount(state),
            searchQuery: getSearchQuery(state),
            currentSiteUser: getCurrentSiteUser(state),
            isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
            isInfoAlwaysShown: isVideosListInfoAlwaysShown(state),
            isInfoShownOnHover: isVideosListInfoShownOnHover(state),
            isDescriptionBelow: isVideosListInfoPositionBelow(state),
            isRTL: isRTL(state),
        }); }, { logWidgetSystem: logWidgetSystem }),
        withStyles(styles)
    ], Videos);
    return Videos;
}(React.Component));
export default Videos;
