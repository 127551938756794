import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './navigation-dots.scss';
var DOT_CONTAINER_SIZE = 12;
var MAIN_DOTS_COUNT = 3;
var isStart = function (selectedIndex) { return selectedIndex < MAIN_DOTS_COUNT; };
var isEnd = function (selectedIndex, count) {
    return count - selectedIndex < MAIN_DOTS_COUNT + 1;
};
var NavigationDots = /** @class */ (function (_super) {
    __extends(NavigationDots, _super);
    function NavigationDots(props) {
        var _this = _super.call(this, props) || this;
        _this.getMove = function (nextSelectedIndex) {
            return (_this.state.cssLeft +
                DOT_CONTAINER_SIZE * (_this.props.selectedIndex - nextSelectedIndex));
        };
        _this.saveRef = function (node) {
            var isRTL = _this.props.isRTL;
            if (node) {
                _this.dotsNode = node;
                var direction = isRTL ? 'right' : 'left';
                _this.dotsNode.style[direction] = _this.state.cssLeft + "px";
            }
        };
        var isDirectedRight = props.selectedIndex < MAIN_DOTS_COUNT;
        _this.state = {
            cssLeft: _this.getInitialCssLeft(props, isDirectedRight),
            needDotsRecalc: true,
            isDirectedRight: isDirectedRight,
        };
        return _this;
    }
    NavigationDots.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var selectedIndex = nextProps.selectedIndex, hasMainDotsOnly = nextProps.hasMainDotsOnly;
        var isIndexChanged = nextProps.selectedIndex !== this.props.selectedIndex;
        var isCountChanged = nextProps.count !== this.props.count;
        if (hasMainDotsOnly) {
            return;
        }
        if (isIndexChanged || isCountChanged) {
            var isDirectedRight = isCountChanged
                ? true
                : nextProps.selectedIndex > this.props.selectedIndex;
            var needDotsRecalc = isCountChanged
                ? true
                : !this.dots || this.isSmallDotBecomingActive(selectedIndex);
            var cssLeft = needDotsRecalc
                ? this.getMove(nextProps.selectedIndex)
                : this.state.cssLeft;
            this.setState({
                needDotsRecalc: needDotsRecalc,
                isDirectedRight: isDirectedRight,
                cssLeft: cssLeft,
            });
        }
    };
    NavigationDots.prototype.componentDidUpdate = function () {
        var cssLeft = this.state.cssLeft;
        var _a = this.props, hasMainDotsOnly = _a.hasMainDotsOnly, isRTL = _a.isRTL;
        if (!hasMainDotsOnly) {
            var direction = isRTL ? 'right' : 'left';
            this.dotsNode.style[direction] = cssLeft + "px";
        }
    };
    NavigationDots.prototype.getInitialCssLeft = function (props, isDirectedRight) {
        if (props === void 0) { props = this.props; }
        if (isDirectedRight === void 0) { isDirectedRight = this.state.isDirectedRight; }
        var selectedIndex = props.selectedIndex, count = props.count, hasMainDotsOnly = props.hasMainDotsOnly;
        var initialStartCssLeft = 2 * DOT_CONTAINER_SIZE;
        var initialEndCssLeft = DOT_CONTAINER_SIZE * (5 - count);
        var startDotIndex = 0;
        var endDotIndex = count - 3;
        if (hasMainDotsOnly) {
            return -0.5 * count * DOT_CONTAINER_SIZE;
        }
        if (isStart(selectedIndex)) {
            return initialStartCssLeft;
        }
        if (isEnd(selectedIndex, count)) {
            return initialEndCssLeft;
        }
        return isDirectedRight
            ? initialStartCssLeft +
                DOT_CONTAINER_SIZE * (startDotIndex - selectedIndex)
            : initialEndCssLeft + DOT_CONTAINER_SIZE * (endDotIndex - selectedIndex);
    };
    NavigationDots.prototype.renderDot = function (dot, index) {
        var _a;
        var className = classnames(styles.dot, (_a = {},
            _a[styles.large] = dot.large,
            _a[styles.near] = dot.near,
            _a[styles.active] = dot.active,
            _a));
        return (React.createElement("div", { className: styles['dot-wrapper'], key: index },
            React.createElement("div", { className: className })));
    };
    NavigationDots.prototype.isSmallDotBecomingActive = function (selectedIndex) {
        return !_.get(this.dots[selectedIndex], 'large');
    };
    NavigationDots.prototype.getSimplifiedDots = function () {
        var _a;
        var _b = this.props, count = _b.count, selectedIndex = _b.selectedIndex, isRTL = _b.isRTL;
        var dots = _.times(count, function () { return ({ near: true, large: true }); });
        _.nth(dots, selectedIndex).active = true;
        var className = classnames(styles.dots, styles.simplified);
        var direction = isRTL ? 'right' : 'left';
        var style = (_a = {}, _a[direction] = this.getInitialCssLeft(), _a);
        return (React.createElement("div", { "data-hook": "navigation-dots", className: className, style: style }, _.map(dots, this.renderDot)));
    };
    NavigationDots.prototype.getAllDots = function () {
        var _a = this.props, selectedIndex = _a.selectedIndex, count = _a.count;
        var isDirectedRight = this.state.isDirectedRight;
        var dots = _.times(count, function () { return ({}); });
        if (isDirectedRight) {
            if (isStart(selectedIndex)) {
                for (var i = 0; i < MAIN_DOTS_COUNT; i += 1) {
                    dots[i].large = true;
                }
            }
            else {
                for (var i = 0; i < MAIN_DOTS_COUNT; i += 1) {
                    dots[selectedIndex - i].large = true;
                }
            }
        }
        else if (isEnd(selectedIndex, count)) {
            for (var i = 1; i < MAIN_DOTS_COUNT + 1; i += 1) {
                dots[count - i].large = true;
            }
        }
        else {
            for (var i = 0; i < MAIN_DOTS_COUNT; i += 1) {
                dots[selectedIndex + i].large = true;
            }
        }
        var firstLargeIndex = _.findIndex(dots, 'large');
        var nearDotsIndexes = _.times(MAIN_DOTS_COUNT + 2, function (index) { return firstLargeIndex + index - 1; });
        dots = _.map(dots, function (dot, index) {
            return _.includes(nearDotsIndexes, index) ? __assign(__assign({}, dot), { near: true }) : dot;
        });
        return dots;
    };
    NavigationDots.prototype.withActiveDotUpdated = function (dots) {
        var selectedIndex = this.props.selectedIndex;
        return _.map(dots, function (dot, index) {
            dot.active = index === selectedIndex;
            return dot;
        });
    };
    NavigationDots.prototype.getDots = function () {
        var needDotsRecalc = this.state.needDotsRecalc;
        var dots = needDotsRecalc ? this.getAllDots() : this.dots;
        dots = this.withActiveDotUpdated(dots);
        this.dots = dots;
        return dots;
    };
    NavigationDots.prototype.render = function () {
        var hasMainDotsOnly = this.props.hasMainDotsOnly;
        return hasMainDotsOnly ? (this.getSimplifiedDots()) : (React.createElement("div", { className: styles['dots-visible-window'] },
            React.createElement("div", { ref: this.saveRef, className: styles.dots, "data-hook": "navigation-dots" }, _.map(this.getDots(), this.renderDot))));
    };
    NavigationDots.propTypes = {
        count: PropTypes.number,
        selectedIndex: PropTypes.number,
        hasMainDotsOnly: PropTypes.bool,
        isRTL: PropTypes.bool,
    };
    NavigationDots.defaultProps = {
        count: 0,
        selectedIndex: 0,
    };
    NavigationDots = __decorate([
        connect(function (state, props) { return ({
            hasMainDotsOnly: props.count <= MAIN_DOTS_COUNT,
        }); }),
        withStyles(styles)
    ], NavigationDots);
    return NavigationDots;
}(React.Component));
export default NavigationDots;
