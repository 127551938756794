import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './grid.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import AutoPlayVideo from '../../../shared/components/autoplay-video/autoplay-video';
import ActionCallbacks from '../../containers/action-callbacks/action-callbacks';
import OpenFullscreenModalShare from '../../containers/open-fullscreen-modal-share/open-fullscreen-modal-share';
import { WidgetPerformanceLoggers } from '../../containers/performance-loggers/performance-loggers';
import PaymentEvents from '../../../shared/components/payment-events/payment-events';
import LiveStartHandler from '../../components/live-start-handler/live-start-handler';
import WithPreviousVideos from './containers/with-previous-videos/with-previous-videos';
import Player from '../../containers/player/player';
import ShareOverlay from '../../containers/share-overlay/share-overlay';
import { withPlayerModuleLoader } from '../../data-components/player-module-loader';
import { ViewModeObserver } from '../../containers/view-mode-observer';
import { CSSGrid } from '@wix/wix-vod-shared/dist/src/widget/ui-components/css-grid/css-grid';
import { PostSsrRuntimeContent } from '@wix/wix-vod-shared/dist/src/common/utils/post-ssr-runtime';
import { VideoAspectRatio } from '@wix/wix-vod-shared/dist/src/common/components/video-aspect-ratio';
/* selectors */
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVideoPlayingOptimistic } from '../../selectors/video-playback-status';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getIsFetching, getCursor, } from '../../redux/client/lazy-channel-videos/selectors';
import { getCurrentSiteUser } from '../../../shared/selectors/current-site-user';
import { getMainVideo } from '../../selectors/get-video';
import { showAutoPlay, isPlayerActive } from '../../selectors/layout';
import { getCategory } from '../../../shared/selectors/search';
import { getNumberOfRows, getStretchToFullWidth, getContainerMargins, isPlayInFrame, getThumbnailSpacing, getVideosInRowCount, } from '../../../shared/selectors/app-settings';
import { getThumbnailWidth, getMaxVideosCountInRow, getRowSpacing, } from './selectors';
/* utils */
import getThumbnailMinWidthAttribute from '@wix/wix-vod-shared/dist/src/widget/ui-components/slider/get-thumbnail-min-width-attribute';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from '../../redux/client/actions/full-screen-modal';
import { selectVideo } from '../../redux/client/actions/select-video';
import { loadMoreVideoPages } from '../../redux/client/lazy-channel-videos/actions';
import { requestPlayVideo } from '../../redux/client/actions/request-play-video';
import { resetSearch } from '../../redux/client/actions/search';
import { setWidgetHeight } from '../../../shared/worker/actions/resize/set-widget-height';
import { resizeComponent } from '../../../shared/worker/actions/resize/resize-component';
import VideoThumbnail from '../../../shared/components/video-thumbnail/video-thumbnail';
import ActionBar from '../../components/action-bar/action-bar';
import LoadMoreButton from './components/load-more-button/load-more-button';
import BallsLoader from './components/balls-loader/balls-loader';
import NoResults from '../components/no-results/no-results';
import { logWidgetSystem } from '../../../shared/worker/actions/bi';
import { logWidgetVidClick } from '../../../shared/utils/bi/widget-common-events';
/* translations */
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { FULL_WIDTH_MARGINS } from './constants';
import { MAX_WIDGET_WIDTH } from '../../utils/videos-sizes/videos-sizes';
import { VIDEOS_ASPECT_RATIO } from '../../constants/videos-aspect-ratio';
import { WIDTH_CONSTRAINTS } from '../../constants/thumbnail-sizes';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import * as viewModeSelectors from '../../selectors/view-mode';
import { getCompId } from '../../redux/client/hydrated-data/hydrated-data';
import fedopsLogger from '../../../loggers/fedops/new-fedops-logger';
var GridLayout = /** @class */ (function (_super) {
    __extends(GridLayout, _super);
    function GridLayout(props) {
        var _this = _super.call(this, props) || this;
        _this.reset = function (_a) {
            var editMode = _a.editMode;
            var _b = _this.props, numberOfRows = _b.numberOfRows, mainVideoId = _b.mainVideoId;
            if (_this.state.numberOfRows !== numberOfRows) {
                _this.setState({ numberOfRows: numberOfRows });
            }
            if (_this.playerContainerRef &&
                _this.playerRef &&
                editMode === VIEW_MODES.EDITOR) {
                _this.playerContainerRef.appendChild(_this.playerRef);
            }
            if (editMode === VIEW_MODES.PREVIEW) {
                _this.attachPlayerToSelectedThumbnail(mainVideoId);
            }
        };
        _this.setCurrentVideoFromPayment = function (_a) {
            var itemId = (_a === void 0 ? {} : _a).itemId;
            if (itemId) {
                _this.props.selectVideo(itemId);
            }
        };
        _this.logVideoPlayRequested = function (videoItem) {
            var channel = _this.props.channel;
            _this.props.logWidgetVidClick({ videoItem: videoItem, channelData: channel });
        };
        _this.saveThumbnailRef = function (videoId, ref) {
            _this.thumbnailRefs[videoId] = ref;
            if (videoId === _this.props.mainVideoId) {
                _this.attachPlayerToSelectedThumbnail(_this.props.mainVideoId);
            }
        };
        _this.renderThumbnail = function (videoId) {
            var _a = _this.props, channel = _a.channel, isSearching = _a.isSearching, videoByIds = _a.videoByIds, currentSiteUser = _a.currentSiteUser, itemWidth = _a.itemWidth, rowSpacing = _a.rowSpacing, thumbnailSpacing = _a.thumbnailSpacing;
            var video = videoByIds[videoId];
            if (!video) {
                return React.createElement("div", { key: videoId, style: { width: itemWidth } });
            }
            return (React.createElement("div", { style: { marginTop: rowSpacing - 2, marginLeft: thumbnailSpacing }, key: videoId, ref: memoizedPartial(_this.saveThumbnailRef, videoId), className: styles.thumbnail },
                React.createElement(ActionCallbacks, { channelId: channel.id, videoItem: video, onPlayRequestedBi: _this.logVideoPlayRequested },
                    React.createElement(VideoThumbnail, { videoItem: video, isLoading: isSearching, dataHook: "video-list-thumb-wrapper", channelData: channel, currentSiteUser: currentSiteUser, width: itemWidth, isContentFocusable: true, breakpoints: [
                            {
                                min: 0,
                                width: itemWidth,
                                height: itemWidth / VIDEOS_ASPECT_RATIO,
                            },
                        ] }))));
        };
        _this.loadMoreVideos = function () {
            var _a = _this.props, loadMoreVideoPages = _a.loadMoreVideoPages, hasMoreVideos = _a.hasMoreVideos, numberOfRows = _a.numberOfRows, isFetching = _a.isFetching;
            if (isFetching) {
                return;
            }
            _this.props.logWidgetSystem('videoList.loadMore.requested');
            var loadPromise = hasMoreVideos
                ? loadMoreVideoPages()
                : Promise.resolve();
            loadPromise.then(function () {
                _this.setState({
                    numberOfRows: _.clamp(_this.state.numberOfRows + numberOfRows, 1, _this.getNumberOfRows()),
                });
                _this.props.logWidgetSystem('videoList.loadMore.rendered');
            });
        };
        _this.saveContainerRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.playVideo = function (_a) {
            var id = _a.id;
            var _b = _this.props, channel = _b.channel, requestPlayVideo = _b.requestPlayVideo, openFullScreenVideoOverlay = _b.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _b.closeFullScreenVideoOverlay, isPlayInFrame = _b.isPlayInFrame, isPortableDevice = _b.isPortableDevice;
            if (isPlayInFrame || isPortableDevice) {
                requestPlayVideo(id);
                return;
            }
            openFullScreenVideoOverlay(channel.id, id, true, closeFullScreenVideoOverlay);
        };
        _this.resetSearch = function () {
            var _a = _this.props, resetSearch = _a.resetSearch, numberOfRows = _a.numberOfRows;
            _this.setState({ numberOfRows: numberOfRows });
            resetSearch();
        };
        _this.savePlayerContainerRef = function (ref) {
            _this.playerContainerRef = ref;
        };
        _this.savePlayerRef = function (ref) {
            _this.playerRef = ref;
        };
        _this.state = {
            numberOfRows: props.numberOfRows,
        };
        _this.forcedWidth = 0;
        _this.thumbnailRefs = {};
        _this.playerRef = null;
        _this.playerContainerRef = null;
        return _this;
    }
    GridLayout.prototype.componentDidMount = function () {
        this.resizeWidget();
        this.attachPlayerToSelectedThumbnail(this.props.mainVideoId);
    };
    GridLayout.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var numberOfRows = _a.numberOfRows, isFullWidth = _a.isFullWidth, isFetching = _a.isFetching, mainVideoId = _a.mainVideoId;
        if (numberOfRows !== this.props.numberOfRows) {
            this.setState({ numberOfRows: numberOfRows });
        }
        if (isFullWidth !== this.props.isFullWidth && !isFullWidth) {
            this.forcedWidth = MAX_WIDGET_WIDTH;
        }
        if (mainVideoId !== this.props.mainVideoId) {
            this.attachPlayerToSelectedThumbnail(mainVideoId);
        }
    };
    GridLayout.prototype.componentDidUpdate = function () {
        if (this.forcedWidth && this.props.windowSize.width === MAX_WIDGET_WIDTH) {
            this.forcedWidth = 0;
        }
        this.resizeWidget();
    };
    GridLayout.prototype.attachPlayerToSelectedThumbnail = function (mainVideoId) {
        var thumbnailRef = this.thumbnailRefs[mainVideoId];
        if (!thumbnailRef || !this.playerRef) {
            return;
        }
        if (thumbnailRef.contains(this.playerRef)) {
            return;
        }
        thumbnailRef.appendChild(this.playerRef);
    };
    GridLayout.prototype.resizeWidget = function () {
        var _a = this.props, windowSize = _a.windowSize, isFullWidth = _a.isFullWidth, isEditorMode = _a.isEditorMode, setWidgetHeight = _a.setWidgetHeight, resizeComponent = _a.resizeComponent;
        var width = this.containerRef.clientWidth;
        var height = this.containerRef.clientHeight;
        var nextWindowSize = { width: width, height: height };
        if (_.isEqual(windowSize, nextWindowSize)) {
            return;
        }
        if (isFullWidth) {
            setWidgetHeight(height, width);
            return;
        }
        if (isEditorMode) {
            // Viewer does not support resizeComponent
            resizeComponent({ width: width, height: height });
        }
        else {
            setWidgetHeight(height, width);
        }
    };
    GridLayout.prototype.renderGridContent = function () {
        var _a = this.props, mainVideo = _a.mainVideo, isFetching = _a.isFetching, selectedCategory = _a.selectedCategory, hasMoreVideos = _a.hasMoreVideos, videoIds = _a.videoIds;
        if (!isFetching && selectedCategory && !hasMoreVideos && !videoIds.length) {
            return this.renderEmptySearchState(i18n.t('widget.categories.no-videos-in-category'));
        }
        return mainVideo ? this.renderThumbnailsCSSGrid() : this.renderEmptyState();
    };
    GridLayout.prototype.getContentAriaLabel = function () {
        var _a = this.props, videoIds = _a.videoIds, selectedCategory = _a.selectedCategory, hasMoreVideos = _a.hasMoreVideos;
        var options = {
            videosCount: videoIds.length,
        };
        var moreVideosAvailable = hasMoreVideos
            ? i18n.t('widget.a11y.grid.more-videos-available')
            : '';
        var ariaLabel = selectedCategory
            ? i18n.t('widget.a11y.grid.videos-for-category', __assign(__assign({}, options), { category: selectedCategory }))
            : i18n.t('widget.a11y.grid.videos', options);
        return [ariaLabel, moreVideosAvailable].join(' ');
    };
    GridLayout.prototype.renderThumbnailsCSSGrid = function () {
        var _a = this.props, videoIds = _a.videoIds, videosInRow = _a.videosInRow, rowSpacing = _a.rowSpacing, thumbnailSpacing = _a.thumbnailSpacing, styleId = _a.styleId;
        var numberOfRows = this.state.numberOfRows;
        var grid = _.range(0, numberOfRows * videosInRow);
        var gridVideoIds = _(grid)
            .map(function (value) { return "fake-item-" + value; })
            .assign(videoIds)
            .value();
        var gridId = 'grid-layout-items';
        var maxRows = Math.ceil(videoIds.length / videosInRow);
        var rows = _.clamp(numberOfRows, 1, maxRows);
        // TODO: maxItemsPerRow
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { "data-hook": "grid-content", tabIndex: "0", "aria-label": this.getContentAriaLabel(), className: styles.content },
                React.createElement(CSSGrid, { styleId: styleId, gridId: gridId, style: {
                        gridAutoRows: 0,
                        overflowY: 'hidden',
                        marginTop: -rowSpacing,
                        marginLeft: -thumbnailSpacing,
                    }, rows: rows, cols: videosInRow, minItemWidth: WIDTH_CONSTRAINTS[0] }, _.map(gridVideoIds, this.renderThumbnail)),
                React.createElement(PostSsrRuntimeContent, null,
                    React.createElement("script", { dangerouslySetInnerHTML: {
                            __html: "\n                  (function () {\n                    var gridContent = document.querySelector('." + styleId + "-" + gridId + "');\n                    var width = gridContent.clientWidth;\n                    var itemWidth = " + WIDTH_CONSTRAINTS[0] + ";\n                    var spacing = " + thumbnailSpacing + ";\n                    var takenWidth = 0;\n                    var itemsCount = 1;\n                    var gridTemplateColumns = [];\n\n                    while (itemsCount <= " + videosInRow + ") {\n                      itemsCount++;\n                      takenWidth = itemsCount * itemWidth + (itemsCount - 1) * spacing;\n                      gridTemplateColumns.push('minmax(" + WIDTH_CONSTRAINTS[0] + "px, 1fr)');\n                      if (takenWidth >= width) {\n                        break;\n                      }\n                    }\n\n                    gridContent.style.gridTemplateColumns = gridTemplateColumns.join(' ');\n                    gridContent.style.msGridColumns = gridTemplateColumns.join(' ');\n                  })();\n                ",
                        } })))));
    };
    GridLayout.prototype.renderEmptyState = function () {
        fedopsLogger.renderNoContent.start();
        fedopsLogger.renderNoContent.end();
        return (React.createElement("div", { "data-hook": "grid-empty", className: styles.empty }, !this.props.isFetching && (React.createElement("div", { className: styles.emptyContent }, i18n.t('widget.this-channel-is-coming-soon')))));
    };
    GridLayout.prototype.renderActions = function () {
        var _a = this.props, channel = _a.channel, containerMargins = _a.containerMargins, isFullWidth = _a.isFullWidth;
        return (React.createElement(ActionBar, { channelData: channel, style: {
                padding: isFullWidth && containerMargins < 20 ? '0 20px' : 0,
            }, className: styles['action-bar-container'] }));
    };
    GridLayout.prototype.renderLoadMoreButton = function () {
        var isFetching = this.props.isFetching;
        return (React.createElement(LoadMoreButton, { dataHook: "load-more-button", isLoading: isFetching, onClick: this.loadMoreVideos, ariaLabel: i18n.t('widget.load-more') }, isFetching ? React.createElement(BallsLoader, null) : i18n.t('widget.load-more')));
    };
    GridLayout.prototype.shouldDisplayLoadMoreButton = function () {
        var hasMoreVideos = this.props.hasMoreVideos;
        return hasMoreVideos || this.state.numberOfRows < this.getNumberOfRows();
    };
    GridLayout.prototype.getNumberOfRows = function () {
        var _a = this.props, videoIds = _a.videoIds, videosInRow = _a.videosInRow;
        return Math.ceil(videoIds.length / videosInRow);
    };
    GridLayout.prototype.renderEmptySearchState = function (message) {
        return (React.createElement(NoResults, { className: styles.empty, dataHook: "grid-empty-search-results", message: message, onButtonClick: this.resetSearch }));
    };
    GridLayout.prototype.renderPlayer = function () {
        var _a;
        var _b = this.props, channel = _b.channel, mainVideo = _b.mainVideo, isPlayerActive = _b.isPlayerActive, showAutoPlay = _b.showAutoPlay, PlayerComponent = _b.PlayerComponent;
        if (!mainVideo) {
            return null;
        }
        return (React.createElement("div", { "data-hook": "player-wrapper", ref: this.savePlayerRef, className: classnames(styles.player, (_a = {},
                _a[styles.active] = isPlayerActive,
                _a)) },
            React.createElement(VideoAspectRatio, null,
                React.createElement(Player, { PlayerComponent: PlayerComponent, fillAllSpace: true }),
                React.createElement(ShareOverlay, { key: channel.id, channelData: channel, videoItem: mainVideo })),
            showAutoPlay && React.createElement(AutoPlayVideo, null)));
    };
    GridLayout.prototype.render = function () {
        var _a = this.props, channel = _a.channel, isFullWidth = _a.isFullWidth, containerMargins = _a.containerMargins, itemWidth = _a.itemWidth, mainVideoId = _a.mainVideoId, isVideoPlaying = _a.isVideoPlaying, isSearching = _a.isSearching, videoIds = _a.videoIds, isPlayInFrame = _a.isPlayInFrame;
        var horizontalPadding = isFullWidth
            ? containerMargins
            : FULL_WIDTH_MARGINS;
        var containerStyles = {
            padding: isFullWidth
                ? FULL_WIDTH_MARGINS + "px " + containerMargins + "px"
                : FULL_WIDTH_MARGINS + "px",
            minWidth: (this.forcedWidth || itemWidth + horizontalPadding * 2) + "px",
            minHeight: isSearching || !videoIds.length ? '100vh' : 'unset',
        };
        return (React.createElement("main", { className: styles.container, style: containerStyles, "data-thumbnail-min-width": getThumbnailMinWidthAttribute(itemWidth), "data-hook": "widget-container", "data-channel-layout": "grid", ref: this.saveContainerRef, "aria-label": i18n.t('widget.accessibility.channel-videos-widget', {
                channelTitle: channel.title,
            }), tabIndex: "0" },
            isPlayInFrame && (React.createElement("div", { ref: this.savePlayerContainerRef, style: { display: 'none' } }, this.renderPlayer())),
            this.renderActions(),
            this.renderGridContent(),
            this.shouldDisplayLoadMoreButton() && this.renderLoadMoreButton(),
            React.createElement(OpenFullscreenModalShare, { itemWidth: itemWidth }),
            React.createElement(PaymentEvents, { onRent: this.setCurrentVideoFromPayment, onSale: this.setCurrentVideoFromPayment }),
            React.createElement(WidgetPerformanceLoggers, null),
            React.createElement(LiveStartHandler, { playVideo: this.playVideo, isVideoPlaying: isVideoPlaying, selectedVideoId: mainVideoId }),
            React.createElement(ViewModeObserver, { onChange: this.reset })));
    };
    GridLayout.propTypes = {
        styleId: PropTypes.string.isRequired,
        isEditorMode: PropTypes.bool.isRequired,
        windowSize: PropTypes.object.isRequired,
        channel: PropTypes.object.isRequired,
        videoIds: PropTypes.array.isRequired,
        videoByIds: PropTypes.object.isRequired,
        currentSiteUser: PropTypes.object,
        selectVideo: PropTypes.func.isRequired,
        mainVideo: PropTypes.object,
        mainVideoId: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        itemWidth: PropTypes.number.isRequired,
        numberOfRows: PropTypes.number.isRequired,
        videosInRow: PropTypes.number.isRequired,
        gridColumns: PropTypes.number.isRequired,
        isFullWidth: PropTypes.bool.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        containerMargins: PropTypes.number.isRequired,
        rowSpacing: PropTypes.number.isRequired,
        thumbnailSpacing: PropTypes.number.isRequired,
        hasMoreVideos: PropTypes.string,
        loadMoreVideoPages: PropTypes.func.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        openFullScreenVideoOverlay: PropTypes.func.isRequired,
        closeFullScreenVideoOverlay: PropTypes.func.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        isSearching: PropTypes.bool.isRequired,
        selectedCategory: PropTypes.string,
        resetSearch: PropTypes.func.isRequired,
        showAutoPlay: PropTypes.bool,
        isPlayerActive: PropTypes.bool,
        setWidgetHeight: PropTypes.func.isRequired,
        resizeComponent: PropTypes.func.isRequired,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
    };
    GridLayout = __decorate([
        connect(function (state) { return ({
            styleId: getCompId(state),
            windowSize: state.windowSize,
            isEditorMode: viewModeSelectors.isEditorMode(state),
            channel: getChannelForWidget(state),
            currentSiteUser: getCurrentSiteUser(state),
            mainVideo: getMainVideo(state),
            mainVideoId: getMainVideoId(state),
            isFetching: getIsFetching(state),
            itemWidth: getThumbnailWidth(state),
            isVideoPlaying: isVideoPlayingOptimistic(state),
            numberOfRows: getNumberOfRows(state),
            videosInRow: getMaxVideosCountInRow(state),
            gridColumns: getVideosInRowCount(state),
            isFullWidth: getStretchToFullWidth(state),
            containerMargins: getContainerMargins(state),
            rowSpacing: getRowSpacing(state),
            thumbnailSpacing: getThumbnailSpacing(state),
            hasMoreVideos: getCursor(state),
            isPlayInFrame: isPlayInFrame(state),
            selectedCategory: getCategory(state),
            showAutoPlay: showAutoPlay(state),
            isPlayerActive: isPlayerActive(state),
        }); }, {
            selectVideo: selectVideo,
            loadMoreVideoPages: loadMoreVideoPages,
            resetSearch: resetSearch,
            requestPlayVideo: requestPlayVideo,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
            logWidgetSystem: logWidgetSystem,
            logWidgetVidClick: logWidgetVidClick,
            setWidgetHeight: setWidgetHeight,
            resizeComponent: resizeComponent,
        }),
        withStyles(styles)
    ], GridLayout);
    return GridLayout;
}(React.Component));
export { GridLayout };
export default withPlayerModuleLoader(WithPreviousVideos(GridLayout));
