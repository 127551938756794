import { __decorate, __extends, __read, __spread } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '@wix/wix-vod-shared/dist/src/widget/ui-components/dropdown/dropdown';
import { updateSearch } from '../../../redux/client/actions/search';
import { getCategory } from '../../../../shared/selectors/search';
import { getChannelCategoryOptions } from '../../../selectors/categories';
import { resetPlaybackStatus } from '../../../redux/client/actions/player/change-playback-status';
import { isActionBarPreview, isRTL, } from '../../../../shared/selectors/app-settings';
import SEARCH_QUERY_FIELDS from '../../../../shared/constants/search-query-fields';
import { logBi, logWidgetSystem } from '../../../../shared/worker/actions/bi';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './categories-dropdown.scss';
var CategoriesDropdown = /** @class */ (function (_super) {
    __extends(CategoriesDropdown, _super);
    function CategoriesDropdown() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getOptionByValue = function (value) { return _.find(_this.props.categoryOptions, { value: value }); };
        _this.handleChange = function (option) {
            var _a = _this.props, updateSearch = _a.updateSearch, selectedCategory = _a.selectedCategory, resetPlaybackStatus = _a.resetPlaybackStatus;
            if (selectedCategory !== option.value) {
                updateSearch({
                    searchQuery: option.value,
                    searchQueryField: SEARCH_QUERY_FIELDS.CATEGORY,
                });
                resetPlaybackStatus();
            }
            _this.props.logWidgetSystem('videoList.searchByCategory.requested');
            _this.props.logBi('widget.categories.selected');
        };
        _this.handleDropdownToggle = function (isOpen) {
            if (isOpen) {
                _this.props.logBi('widget.categories.clicked');
            }
        };
        return _this;
    }
    CategoriesDropdown.prototype.render = function () {
        var _a;
        var _b = this.props, className = _b.className, selectedCategory = _b.selectedCategory, categoryOptions = _b.categoryOptions, isOpen = _b.isOpen, isRTL = _b.isRTL;
        return (React.createElement("div", { className: className, "data-hook": "categories-dropdown" },
            React.createElement(Dropdown, { options: categoryOptions, isOpen: isOpen, openerClassName: styles.dropdownContainer, valueClassName: classnames(styles.value, (_a = {},
                    _a[styles.pristine] = !selectedCategory,
                    _a)), ariaLabel: i18n.t('widget.accessibility.select-category'), arrowClassName: styles.arrow, optionClassName: styles.option, optionsContainerClassName: styles.popoverMenuContainer, popoverClassName: styles.popoverMenu, onChange: this.handleChange, selectedOption: this.getOptionByValue(selectedCategory), onToggle: this.handleDropdownToggle, isRTL: isRTL })));
    };
    CategoriesDropdown.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        className: PropTypes.string,
        selectedCategory: PropTypes.string,
        updateSearch: PropTypes.func.isRequired,
        resetPlaybackStatus: PropTypes.func.isRequired,
        categoryOptions: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            key: PropTypes.string,
        })).isRequired,
    };
    CategoriesDropdown = __decorate([
        connect(function (state) { return ({
            isOpen: isActionBarPreview(state),
            categoryOptions: __spread([
                {
                    label: i18n.t('widget.categories.all-videos'),
                    value: '',
                }
            ], getChannelCategoryOptions(state)),
            selectedCategory: getCategory(state),
            isRTL: isRTL(state),
        }); }, { updateSearch: updateSearch, resetPlaybackStatus: resetPlaybackStatus, logBi: logBi, logWidgetSystem: logWidgetSystem }),
        withStyles(styles)
    ], CategoriesDropdown);
    return CategoriesDropdown;
}(React.Component));
export default CategoriesDropdown;
